<template>
  <v-item-group
    v-model="contentType"
    class="grid grid-cols-2 gap-4"
    mandatory
    tag="div"
  >
    <v-item
      v-for="{ title, value } in items"
      :key="value"
      v-slot="{ toggle, isSelected }"
      :value="value"
    >
      <v-card
        :class="{ 'bg-primary': isSelected }"
        :title="title"
        @click="toggle"
      />
    </v-item>
  </v-item-group>
</template>

<script lang="ts" setup>
const contentType = defineModel<DatabaseEnum<"course_content_type">>({
  default: undefined,
});

defineProps<{
  required?: boolean;
  clearable?: boolean;
}>();

const items: {
  title: string;
  value: DatabaseEnum<"course_content_type">;
}[] = [
  {
    title: "Course",
    value: "COURSE",
  },
  {
    title: "Masterclass",
    value: "MASTERCLASS",
  },
];
</script>
