<template>
  <div class="flex flex-wrap items-center gap-4">
    <div class="basis-full">
      <course-content-type-button-toggle v-model="contentType" />
    </div>

    <course-format-select
      v-if="contentType === 'COURSE'"
      v-model="format"
      data-cy-format
      required
    />

    <app-form-input-text
      v-else
      v-model="hourFormat"
      class="w-full max-w-[150px]"
      hide-details
      label="Duration"
      required
    >
      <template #append-inner>
        <span class="text-subtle text-sm">hours</span>
      </template>
    </app-form-input-text>

    <div v-if="certifyingField" class="basis-full rounded border p-2">
      <v-switch
        v-model="certifying"
        class="ml-2"
        color="primary"
        hint="Certifying course will automatically deliver a Pollen certificate to the learners who filled the post session satisfaction form."
        label="Is certifying"
        persistent-hint
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import CourseContentTypeButtonToggle from "~/business-areas/course/components/course-content-type-button-toggle.vue";

const contentType = defineModel<DatabaseEnum<"course_content_type">>(
  "contentType",
  { required: true },
);
const format = defineModel<DatabaseCompositeType<"course_format">>("format", {
  required: true,
});

const certifying = defineModel<boolean>("certifying", { default: true });

withDefaults(
  defineProps<{
    certifyingField?: boolean;
  }>(),
  {
    certifyingField: true,
  },
);

const emit = defineEmits<{
  "update:format": [payload: DatabaseCompositeType<"course_format">];
}>();

const hourFormat = computed<number>({
  get() {
    return formatDuration(format.value.duration ?? "")?.hours ?? 0;
  },
  set(value) {
    emit("update:format", { duration: `PT${value}H`, amount: 1 });
  },
});

watch(contentType, (value) => {
  format.value =
    value === "COURSE"
      ? { duration: "P1D", amount: 1 }
      : { duration: "PT2H", amount: 1 };
});

watch(contentType, (type) => {
  certifying.value = type === "COURSE";
});
</script>
