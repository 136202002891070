<template>
  <div class="relative">
    <v-btn-toggle
      v-model="value"
      density="compact"
      :mandatory="required"
      variant="outlined"
    >
      <v-btn
        v-for="item in items"
        :key="item.label"
        color="primary"
        :data-cy-course-format="JSON.stringify(item.value)"
        :value="item.value"
      >
        {{ item.label }}
      </v-btn>
    </v-btn-toggle>

    <v-scroll-x-reverse-transition>
      <span
        v-if="errorMessage"
        class="text-error absolute bottom-1 left-3 text-xs"
        >{{ errorMessage }}</span
      >
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";

import type { CourseFormat } from "@/business-areas/course/course.model";

const properties = defineProps<{
  modelValue?: CourseFormat;
  required?: boolean;
}>();

const items: { label: string; value: CourseFormat }[] = [
  {
    label: "Full day",
    value: { duration: "P1D", amount: 1 },
  },
  {
    label: "Half day",
    value: { duration: "P0.5D", amount: 1 },
  },
  {
    label: "2H",
    value: { duration: "PT2H", amount: 1 },
  },
  {
    label: "2x2H",
    value: { duration: "PT2H", amount: 2 },
  },
  {
    label: "3x2H",
    value: { duration: "PT2H", amount: 3 },
  },
  {
    label: "4x2H",
    value: { duration: "PT2H", amount: 4 },
  },
  {
    label: "2x4H",
    value: { duration: "PT4H", amount: 2 },
  },
];

const { value, errorMessage } = useField("Format", {
  required: properties.required,
});
</script>
